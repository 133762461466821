import Vue from 'vue'
import Vuex from 'vuex'
import { langDE } from '@/language/lang-de';
import { langEN } from '@/language/lang-en';
import { modulSpieler } from './spieler-store'
import { modulTurnier } from './turnier-store';
import { modulTeam } from './team-store';
import { modulSpiel } from './spiel-store';
import { modulGruppe } from './gruppe-store';
import { modulTeilnahme } from './teilnahme-store';

Vue.use(Vuex)

function getLanguageFile(language) {
  switch (language) {
    case "de":
      return langDE;
    case "en":
      return langEN;
    default:
      return langDE;
  }
}

export default new Vuex.Store({
  state: {
    lang: null,
    informationDialog: {
      show: false,
      headline: "",
      message: ""
    },
    submitModal: {
      show: false,
      success: false,
      headline: "",
      message: ""
    },
    submitModalFinalPhase: {
      show: false,
      headline: "",
      message: "",
      data: [],
    },
    cookieModal: {
      show: localStorage.getItem("cookieModal") == 'false' ? false : true,
    },
    submitModalSuccessSeconds: 1200,
    SPIELER_ROLLEN: {
      GRUENDER: "GRUENDER",
      TEILNEHMER: "TEILNEHMER",
      GAST: "GAST",
    },
    TURNIER_STATUS: {
      ERSTELLT: "ERSTELLT",
      TURNIERPLAN_ERSTELLT: "TURNIERPLAN_ERSTELLT",
      GESTARTET: "GESTARTET",
      GRUPPENPHASE_BEENDET: "GRUPPENPHASE_BEENDET",
      FINALPHASE_GESTARTET: "FINALPHASE_GESTARTET",
      BEENDET: "BEENDET"
    },
    SPIELMODUS: {
      GRUPPEN_SYSTEM: {name: "GRUPPEN_SYSTEM"},
      KO_SYSTEM: {name: "KO_SYSTEM"}
    },
    SPIELART: {
      GRUPPENSPIEL: {name: "GRUPPENSPIEL", countTeams: 0, spielZeit: "spielzeitGruppenphase"},
      FINALE_512: {name: "FINALE_512", countTeams: 512, spielZeit: "spielzeit512"},
      FINALE_256: {name: "FINALE_256", countTeams: 256, spielZeit: "spielzeit256"},
      FINALE_128: {name: "FINALE_128", countTeams: 128, spielZeit: "spielzeit128"},
      FINALE_64: {name: "FINALE_64", countTeams: 64, spielZeit: "spielzeit64"},
      FINALE_32: {name: "FINALE_32", countTeams: 32, spielZeit: "spielzeit32"},
      FINALE_16: {name: "FINALE_16", countTeams: 16, spielZeit: "spielzeit16"},
      FINALE_8: {name: "FINALE_8", countTeams: 8, spielZeit: "spielzeit8"},
      FINALE_4: {name: "FINALE_4", countTeams: 4, spielZeit: "spielzeit4"},
      FINALE_PLATZ3: {name: "FINALE_PLATZ3", countTeams: 3, spielZeit: "spielzeitPlatz3"},
      FINALE: {name: "FINALE", countTeams: 2, spielZeit: "spielzeitFinale"},
      EINZELSPIEL: { name: "EINZELSPIEL", countTeams: 0, spielZeit: "spielzeitEinzel"}
    }
  },
  mutations: {
    SET_SHOW_INFORMATION_DIALOG(state, informationDialog) {
      state.informationDialog = informationDialog;
    },
    SET_SHOW_INFORMATION_DIALOG_STATUS(state, status) {
      state.informationDialog.show = status;
    },
    SET_SHOW_SUBMIT_MODAL(state, submitModal) {
      state.submitModal = submitModal;
    },
    SET_SHOW_SUBMIT_MODAL_STATUS(state, status) {
      state.submitModal.show = status;
    },
    SET_SHOW_SUBMIT_MODAL_FINAL_PHASE(state, submitModalFinalPhase) {
      state.submitModalFinalPhase = submitModalFinalPhase;
    },
    SET_SHOW_SUBMIT_MODAL_FINAL_PHASE_STATUS(state, status) {
      state.submitModalFinalPhase.show = status;
    },
    SET_SHOW_SUBMIT_MODAL_FINAL_PHASE_DATA(state, data) {
      state.submitModalFinalPhase.data = data;
    },
    SET_SHOW_COOKIE_MODAL(state, cookieModal) {
      state.cookieModal = cookieModal;
    },
    SET_LANGUAGE(state, lang) {
      state.lang = getLanguageFile(lang);
      localStorage.setItem("language", lang);
    }
  },
  actions: {

  },
  getters: {
    getLanguage: (state) => {
      return state.lang;
    },
    getLanguageName: (state) => {
      return state.lang.name;
    },
    getSpielerRolle: (state) => {
      return state.SPIELER_ROLLEN;
    },
    getSpielModus: (state) => {
      return state.SPIELMODUS;
    },
    getTurnierStatus: (state) => {
      return state.TURNIER_STATUS;
    },
    getSpielArt: (state) => {
      return state.SPIELART;
    },
    getInformationDialog: (state) => {
      return state.informationDialog;
    },
    getSubmitModal: (state) => {
      return state.submitModal;
    },
    getSubmitModalFinalPhase: (state) => {
      return state.submitModalFinalPhase;
    },
    getCookieModal: (state) => {
      return state.cookieModal;
    }
  },
  modules: {
    spieler: modulSpieler,
    turnier: modulTurnier,
    team: modulTeam,
    spiele: modulSpiel,
    gruppe: modulGruppe,
    teilnahme: modulTeilnahme
  }
})
