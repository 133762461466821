import { SpringBackend } from '../config/backend-config'
import store from '@/store/index'

export const modulTeam = {
    state: {
        teamsArray: [],
        loadingStatus: false,
        apiReponseErrorMessage: '',
        isDataLoaded: false
    },
    mutations: {
        SET_TEAMS_ARRAY(state, teamsArray) {
            state.teamsArray = teamsArray.sort((a, b) => a.team.teamName.localeCompare(b.team.teamName));
        },
        // ADD_TEAM(state, team) {
        //     state.teamsArray.push(team);
        // },
        // DELETE_TEAM(state, teamID) {
        //     let index = state.teamsArray.findIndex((t) => t.teamID == teamID);
        //     if (index != -1) {
        //         state.teamsArray.splice(index, 1);
        //     }
        // },
        // UPDATE_TEAM(state, team) {
        //     //Find index of specific object using findIndex method.
        //     let oldTeamIndex = state.teamsArray.findIndex((t => t.teamID == team.teamID));
        //     state.teamsArray[oldTeamIndex] = team;
        // },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_DATA_IS_LOADED_STATUS(state, isDataLoaded) {
            state.isDataLoaded = isDataLoaded;
        },
        SET_API_RESPONSE_ERROR_MESSAGE(state, apiReponseErrorMessage) {
            state.apiReponseErrorMessage = apiReponseErrorMessage;
        }
    },
    actions: {
        async getTeamsByTurnierID(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/team?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_TEAMS_ARRAY', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_IS_LOADED_STATUS', true);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async postTeam(context, data) {
            context.commit('SET_LOADING_STATUS', true);
                return await SpringBackend.post(`/team?turnierID=${data.turnierID}`, data.team)
                .then(response => {
                    // context.commit('ADD_TEAM', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                })
        },
        async postTeamEmptyAdmin(context, data) {
            context.commit('SET_LOADING_STATUS', true);
                return await SpringBackend.post(`/team/admin?turnierID=${data.turnierID}`, data.team)
                .then(response => {
                    // context.commit('ADD_TEAM', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                })
        },
        async putTeam(context, data) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put(`/team?teamID=${data.team.teamID}&turnierID=${data.turnierID}`, data.team)
            .then(response => {
                    // context.commit('UPDATE_TEAM', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                })
        },
        async putTeamAufbau(context, data) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put(`/teamaufbau?teamID=${data.teamID}&turnierID=${data.turnierID}`)
            .then(response => {
                    // context.commit('UPDATE_TEAM', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                })
        },
        async deleteTeam(context, data) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.delete(`/team?teamID=${data.teamID}&turnierID=${data.turnierID}`)
                .then(response => {
                    // context.commit('DELETE_TEAM', team.teamID);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                });
        },
    },
    getters: {
        getTeams: state => {
            return state.teamsArray;
        },
        getTeamsLength: (state, getters) => {
            return getters.getTeams.length;
        },
        filterTeamByName: (state) => (letters) => {
            return state.teamsArray.filter(t => {
                return t.team.teamName.toLowerCase().includes(letters.toLowerCase())
            })
        },
        getPlayerTeamNumber: (state) => (spielerID) => {
            let teamNumber = 0;
            state.teamsArray.forEach(t => {
                if (t.team.teamAufbau.length != 0) {
                    if (t.team.teamAufbau[0].teamSpielerID.spielerID1 == spielerID ||
                        t.team.teamAufbau[0].teamSpielerID.spielerID2 == spielerID) {
                            teamNumber = t.team.teamID;
                        }
                }
            });
            return teamNumber;
        },
        getTeamByTeamID: (state) => (teamID) => {
            return state.teamsArray.filter(t => t.team.teamID == teamID);
        }
    },
}