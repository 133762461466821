export const langEN = {
    name: "en",
    meta: {
        appTitle: "Beer Pong Friends - Tournament Planner App",
        description: "Save time with the smart Beer Pong tournament planner for your smartphone or laptop.",
        ogTitle: "Beer Pong Friends - Tournament Planner",
        ogUrl: "https://beerpongfriends.com/",
        ogDescription: "Save time with the smart Beer Pong tournament planner for your smartphone or laptop.",
        ogSiteName: "Beer Pong Friends Tournament Planner"
    },
    notFound: {
        label: "Not Found",
        description: "Oops we can't find the page you're looking for",
        buttonText: "Go back"
    },
    pwa: {
        informationModalHeadlineInstall: "Add as an app?",
        informationModalMessageInstall: "NEW: You can add Beer Pong Tournament Planner as an app to your home screen and finally you don't have to use the browser anymore.",
        informationModalMessageInstallIOS: "NEW: Click on the Share icon in the footer and then on \"Add to homescreen\".",
        informationModalHeadlineUpdate: "Update available",
        informationModalMessageUpdate: "Click okay and then reload the page.",
    },
    modal: {
        buttonTextOkay: "Okay",
        buttonTextAgree: "Confirm",
        buttonTextCancel: "Cancel",
        errorHeadline: "Error"
    },
    captcha: {
        expired: "An error occurred while checking the captcha, please try again.",
        error: "An error occurred while checking the captcha, please try again.",
        required: "Please fill out the captcha."
    },
    cookie: {
        headline: "The website uses necessary cookies",
        message: "This website uses necessary cookies - you can find more information on this and your rights as a user in the data protection declaration at the end of the page. Click on „Accept Cookies“ to visit the website.",
        buttonText: "Accept cookies",
        linkImpressum: "Impressum",
        linkDatenschutz: "Privacy Policy",
    },
    offline: {
        label: "Connection lost",
        description: "Oops you are currently offline. The app can only be used to a limited extent.",
        buttonText: "Go back to start",
        snackbarMessage: "Oops the connection is lost. The app can only be used to a limited extent.",
        snackbarButtonText: "OKAY",
        offlineErrorMessage: "An internet connection is necessary."
    },
    error: {
        defaultMessage: "An unexpected error has occurred.",
        formValidationHeadline: "Please check your entries",
        formValidationMessage: "The data entered do not meet the requirements"
    },
    bottomBar: {
        home: "Home",
        turniere: "Tournaments",
        spielPlan: "Games",
        spielerDetail: "Player"
    },
    spielart: {
        GRUPPENSPIEL: "Group stage",
        FINALE_512: "Round of 512",
        FINALE_256: "Round of 256",
        FINALE_128: "Round of 128",
        FINALE_64: "Round of 64",
        FINALE_32: "Round of 32",
        FINALE_16: "Round of 16",
        FINALE_8: "Quarter-final",
        FINALE_4: "Semi-final",
        FINALE_PLATZ3: "3rd place",
        FINALE: "Final"
    },
    spielmodus: {
        GRUPPEN_SYSTEM: "Group stages",
        KO_SYSTEM: "Round robin"
    },
    datenschutz: {
        header1: "Privacy Policy"
    },
    kontakt: {
        header1: "Contact",
        paragraph: "Feel free to contact us for suggestions or comments at"
    },
    startseite: {
        slickHeader1: "Save time",
        slickParagraph1: "No more game planning and complex Excel spreadsheets necessary. The automatic generation of the tournament schedule can save your evening.",
        slickHeader2: "Lean back",
        slickParagraph2: "As an organizer, you no longer have any stress, because the players can easily enter their scores themselves.",
        slickHeader3: "Everything at a glance",
        slickParagraph3: "Date, start time, game times and number of teams are visible for you and your friends at any time.",
        slickHeader4: "Live game schedule",
        slickParagraph4: "The order of play and the rankings of the teams are updated live.",
        navLoginText: "Log In",
        navStartText: "Get started",
        header1: "Beer Pong tournament quickly planned",
        header2: "Save time with the Beer Pong tournament planner for your smartphone or laptop. Tournaments and game schedules are automatically created for you.",
        headerButtonText: "Get started",
        imgAlt1: "Mobile home screen",
        imgAlt2: "Tournament detail view",
        imgAlt3: "Teams view",
        imgAlt4: "Tournament schedule view",
        imgAlt5: "Game view",
        imgAlt6: "Groups view",
        article1Heading: "Features that simplify life",
        slickHeadline1: "Mobile home screen",
        slickList1Element1: "Add the Beer Pong Tournament Planner as an app to your home screen",
        slickList1Element2: "Create a Beer Pong tournament for your friends",
        slickList1Element3: "Join an existing tournament",
        slickList1Element4: "All your active tournaments at a glance",
        slickHeadline2: "Tournament details at a glance",
        slickList2Element1: "Date, start time, creator and much more at a glance",
        slickList2Element2: "Group mode or round robin (1 vs 1) possible",
        slickList2Element3: "Number of teams and groups can be set variably",
        slickList2Element4: "Calculation of the estimated tournament duration",
        slickHeadline3: "List of teams",
        slickList3Element1: "Add your own team to the tournament or join a team",
        slickList3Element2: "Edit or delete teams as a creator",
        slickList3Element3: "Filter list by team names",
        slickHeadline4: "Game schedule overview",
        slickList4Element1: "Group and final games are automatically created for you",
        slickList4Element2: "Change the score at any time as a creator",
        slickList4Element3: "Table and order of play visible",
        slickList4Element4: "Filter games by groups and tables",
        slickHeadline5: "Beer Pong match",
        slickList5Element1: "The team enters the score after time expires or if it wins early",
        slickList5Element2: "Timer for a better gaming experience",
        slickList5Element3: "Game time adjustable by the creator",
        slickHeadline6: "Group and tournament statistics",
        slickList6Element1: "Calculated recommendations for number of groups, group games and teams per group",
        slickList6Element2: "Random division of teams into groups",
        slickList6Element3: "Statistics and placement in the group visible",
        slickList6Element4: "Live update after a game is finished",
        startNowHeaderPart1: "Organize your",
        startNowHeaderPart2: "Beer Pong tournament",
        startNowHeaderPart3: "without much planning",
        footerLinkText1: "Impressum",
        footerLinkText2: "Privacy Policy",
        footerLinkText3: "Contact Us",
    },
    home: {
        headline1: "Beer Pong Tournament Planner",
        headline2Turniere: "Your tournaments",
        headline2EmptyTurnier: "Start your Beer Pong career!",
        textEmptyTurnier: "Organize a Beer Pong tournament for you and your friends and make the evening unforgettable. OR join a tournament and show everyone what a winner looks like.",
        greeting: "Hello",
        teaserTurnierErstellen: "Create tournament",
        teaserTurnierBeitreten: "Join tournament"
    },
    login: {
        headline: "Login",
        imageAlt: "Beer Pong Stock Image",
        buttonText1: "Login",
        buttonText2: "Create Account",
    },
    spielerForm: {
        labelVorname: "First name",
        labelNachname: "Last name",
        labelEMail: "Email",
        labelPasswort: "Password",
        labelPasswortNew: "New Password",
        labelPasswortOld: "Old Password",
        labelPasswortRepeat: "Repeat password",
        labelDatenschutzConfirm1: "Consent to",
        labelDatenschutzConfirm2: "privacy policy",
        labelDatenschutzConfirm3: "",
        vornameRequired: "Please enter your first name",
        vornameMinLength: "letters are necessary at least",
        nachnameRequired: "Please enter your last name",
        nachnameMinLength: "letters are necessary at least",
        passwortRequired: "Please enter a password",
        passwortMinLength: "characters are necessary at least",
        passwortConfirm: "The passwords must match.",
        emailRequired: "Please enter a valid email",
        datenschutzConfirmRequired: "Please agree to the privacy policy.",
        buttonTextAgree: "Confirm",
        buttonTextCancel: "Cancel",
        linkForgotPassword: "Forgot your password?"
    },
    createAccount: {
        headline: "Create Account",
        imageAlt: "Beer Pong Stock Image",
        buttonText1: "Register",
        buttonText2: "Cancel",
        successHeadline: "Successfully registered",
        successMessage: "Please confirm your email address"
    },
    confirmAccount: {
        buttonText: "Login",
        headline1Success: "Account verified",
        messageSuccess: "Thank you for confirming your email address.",
    },
    forgotPasswortConfirm: {
        headline1: "Reset password",
        successHeadline: "Password successfully changed",
        buttonText: "Change",
        buttonTextBack: "Back to Login",
    },
    forgotPasswortEmail: {
        headline1: "Reset password",
        message: "Please, enter your email address to reset your password. You may need to check your spam folder or unblock:",
        emailSender: "no-reply@beerpongfriends.com",
        buttonText: "Submit",
        buttonTextBack: "Back to Login",
        successHeadline: "Email sent"
    },
    spielerDetail: {
        headline: "Player Details",
        successHeadline: "Data successfully changed",
        buttonTextAbbrechen: "Cancel",
        buttonTextAgree: "Confirm",
        editPasswortHeadline: "Change password",
        editSpielerHeadline: "Change player data",
        passwortOldRequired: "Please enter your old password",
        passwortNewRequired: "Please enter a new password",
        erstellteTurniere: "Created tournaments",
        aktiveTurniere: "Active tournaments",
        vergangeneTurniere: "Inactive tournaments",
        changeSpielerDaten: "Change data",
        changePasswort: "Change password",
        changeLanguage: "Change language",
        changeLanguageDE: "German",
        changeLanguageEN: "English",
        logout: "Logout",
    },
    turnierForm: {
        labelTurniername: "Tournament name",
        labelDatum: "Date",
        labelStartzeit: "Start time",
        labelStadt: "Venue",
        labelVeranstaltungsort: "Venue (City)",
        labelPasswort: "Password",
        labelBeschreibung: "Public description (optional)",
        labelTische: "Number of tables",
        labelTeams: "Number of teams",
        labelSpielModusEmpfehlung: "Tournament type recommendation:",
        labelSpielmodus: "Game type",
        labelSpieleKOTeam: "Number of games per team",
        labelSpieleKOAll: "Number of games tournament",
        labelGruppen: "Number of groups",
        labelEmpfehlungGruppen: "Group recommendation:",
        labelSpielerProGruppe: "Number of players per group:",
        labelSpieleGruppenphase: "Number of group stage games:",
        labelGruppenPhaseZweiterWeiter: "2nd place get ahead?",
        labelGruppenPhaseZweiterWeiterDetail: "2nd place get ahead",
        hinweisTextZeit: "Enter 0 for no time limit.",
        labelPausenzeiten: "Break times",
        labelSpielZeitKO: "Playtime",
        labelSpielZeit: "Playtime",
        labelZeitEinheit: "Minutes",
        labelZeitEinheitShort: "Min.",
        labelSpielZeitGruppenphase: "Playtime group stages",
        labelSpielZeit256: "Playtime round of 512",
        labelSpielZeit128: "Playtime round of 256",
        labelSpielZeit64: "Playtime round of 128",
        labelSpielZeit32: "Playtime round of 64",
        labelSpielZeit16: "Playtime round of 32",
        labelSpielZeit8: "Playtime round of 16",
        labelSpielZeit4: "Playtime quarter-final",
        labelSpielZeit2: "Playtime semi-final",
        labelSpielZeitPlatz3: "Playtime 3rd place",
        labelSpielZeitFinale: "Playtime final",
        labelGruender: "Creator",
        labelGeplantePausenzeiten: "Planned break time",
        turnierNameRequired: "Tournament name required",
        turnierNameMinLength: "letters allowed",
        passwortRequired: "Please enter a password",
        passwortMinLength: "characters are necessary at least",
        datumRequired: "Please enter a date",
        startZeitRequired: "Please enter a time",
        anzTeamsRequired: "Please enter a number",
        anzTeamsBetweenValue: "teams possible",
        anzGruppenRequired: "Please enter a number",
        anzGruppenMinValue: "groups at least required",
        pausenZeitenRequired: "Please enter a break time",
        pausenZeitenMinValue: "at least necessary",
        spielzeitRequired: "Please enter a playtime",
        stadtRequired: "Please enter a city",
        spielzeitMinValue: "at least necessary",
        spielModusKO: "Round robin (1 vs 1)",
        spielModusGruppen: "Group stage",
        spielModusNull: "...enter a team number",
        spielZeit: "Tournament duration",
        spielZeitenHinweisHeadline: "Note calculation tournament duration",
        spielZeitenHinweis: "If no time limit is selected, a playing time of approx. 15 minutes is used for the calculation. The break times are included in the calculation.",
        spielZeitenHinweisGruppen: "A group always plays at the same table.",
        spielZeitenHinweisKO: "In the round robin mode, only a maximum of 2 tables are included in the calculation of the tournament duration.",
        turnierDatumHinweisHeadline: "Note tournament date",
        turnierDatumHinweis: "If the tournament is not finished or started seven days after the start, it will be deleted.",
    },
    createTurnier: {
        headline: "Create tournament",
        buttonNext: "Next",
        buttonSubmit: "Create",
        successHeadline: "Successfully created",
    },
    editTurnier: {
        headline: "Edit tournament",
        buttonNext: "Next",
        buttonSubmit: "Change",
        successHeadline: "Successfully changed",
        notEditableMessage: "The tournament has started. These fields are no longer editable."
    },
    listTurniere: {
        headline: "tournaments",
        headlinePrefixPrevious: "",
        headlinePrefixCurrent: "",
        emptyStateLabelActive: "Start something unforgettable",
        emptyStateDescriptionActive: "So far there is no Beer Pong tournament near you. This is your chance to give your friends an unforgettable evening.",
        emptyStateLabelInactive: "No past tournaments available",
        emptyStateDescriptionInactive: "There are no past Beer Pong tournaments near you yet.",
        emptyStateLabelSearch: "No hit",
        emptyStateDescriptionSearch: "Unfortunately, there are no results for your search term.",
        searchInputPlacholder: "Search for a tournament...",
        changeCityFormHeadline: "Location",
        changeCityFormMessage: "Find organized tournaments from your city.",
        stadtRequired: "Please enter your city",
        buttonTextCancel: "Cancel",
        buttonTextAgree: "Confirm",
        labelTeam: "Teams",
        filterVergangeneTurnier: "past tournaments",
        filterAktuelleTurnier: "current tournaments",
        filterLabelStadt: "City",
        searchInputLabel: "Tournament search"
    },
    detailTurnier: {
        textYes: "Yes",
        textNo: "No",
        buttonTextBeitreten: "Participate",
        buttonTextSpiele: "Schedule",
        buttonTextStartenKoPhase: "Create tournament schedule",
        buttonTextStartenGruppenphase: "Create group stage",
        buttonTextStartenFinalPhase: "Create final stage",
        buttonTextTeams: "Teams",
        imageAlt: "Beer Pong Stock Image",
        dialogHeadlineDelete: "Delete the tournament?",
        dialogHeadlineCreateGruppenPhase: "Create group stage?",
        dialogDescriptionCreateGruppenPhase: "The groups and group games are created randomly. A group always plays at the same table.",
        dialogHeadlineStartFinalPhase: "Create final stage?",
        dialogDescriptionStartFinalPhase: "The best players in the group are selected in this order: number of wins, point difference, cups hit.",
        dialogHeadlineKoPhase: "Create tournament schedule?",
        dialogDescriptionKoPhase: "Each team plays against every other registered team, without a rematch.",
        buttonDialogCancel: "Cancel",
        buttonDialogAgree: "Confirm",
        successHeadlineTurnierPlanErstellt: "Successfully created",
        successHeadlineStartFinalPhase: "Successfully created",
        successHeadlineDelete: "Successfully deleted",
        modalTeamsMissingHeadline: "Wait a moment...",
        modalTeamsMissingMessage1: "not all teams have registered yet.. ",
        modalTeamsMissingMessage2: "more necessary. Wait for the last teams, create an empty team or adjust the number of teams in the tournament.",
        modalFinalPhase: "the group stage is not over yet.",
        dialogHeadlinePasswort: "Ask the tournament creator for the password",
        dialogPlaceholderPasswort: "Enter password...",
        buttonDialogCancel: "Cancel",
        buttonDialogAgree: "Confirm",
        emptyStateLabel: "Tournament not available",
        emptyStateDescription: "Unfortunately, the tournament was not found.",
        emptyStateButtonText: "Tournament list",
        modalFinalPhasePlatzierungHeadline: "Make a decision",
        modalFinalPhaseHinweis: "Please select.",
        modalFinalPhaseLinkText: "To group statistics",
        modalFinalPhaseTeamChoose1: "Choose",
        modalFinalPhaseTeamChoose2: "Teams",
        modalFinalPhaseTeamHeadline: " place - group "
    },
    listTeams: {
        headline: "Teams",
        descriptionAdmin: "Players can add their own team or you can create an empty team yourself.",
        descriptionPlayer: "Press the plus to create a team or join a team.",
        NumberOfTeamsActive: "Number of enrolled teams: ",
        buttonTextJoin: "join",
        buttonTextLeave: "leave",
        buttonDialogAgree: "Confirm",
        buttonDialogCancel: "Cancel",
        dialogHeadlineJoin: "Join team?",
        dialogHeadlineLeave: "Leave team?",
        dialogHeadlineDeleteTeam: "Delete team?",
        dialogHeadlineTeam: "What is the name of your team?",
        dialogPlaceholderTeam: "Enter team name...",
        successHeadlineCreate: "Successfully created",
        successHeadlineEdit: "Successfully changed",
        successHeadlineChangeTeamAufbau: "Team change successful",
        successHeadlineLeave: "Successfully exited",
        emptyStateLabel: "Here for Beer?",
        emptyStateDescriptionAdmin: "No teams have registered for the tournament yet. Be the first to participate in Beer Pong Madness. Press the plus button to create a team.",
        emptyStateDescriptionPlayer: "No players have created a team yet. But you can add as many teams as you want. Press the plus button to create a team.",
        modalTeamsFullHeadlineAdmin: "Maximum number reached...",
        modalTeamsFullMessageAdmin: "you can no longer add new teams. To create more teams change the number of allowed teams in the tournament settings.",
        modalTeamsFullHeadlineUser: "Unfortunately too late...",
        modalTeamsFullMessageUser: "you can no longer create a new team. The maximum number has been reached. Maybe you will have more luck next tournament.",
        searchInputPlacholder: "Search for a team...",
        searchInputLabel: "Team search",
        emptyStateLabelSearch: "No hit",
        emptyStateDescriptionSearch: "There are no results for your search term.",
        teamEditDialogHeadline: "Edit team",
        labelTeamName: "Team name"
    },
    turnierplan: {
        headline: "Tournament schedule",
        navElementGruppen: "Groups",
        navElementGruppenSpiele: "Group games",
        navElementTurnierbaum: "Finals",
        navElementRanking: "Ranking",
        navElementSingleSpiele: "Games",
        emptyStateLabel: "Tournament starts soon",
        emptyStateDescription: "As soon as all teams have registered and the tournament creator releases the tournament, the fun can begin.",
        emptyStateLabelSpiele: "Be part of it!",
        emptyStateDescriptionSpiele: "Enroll in an active tournament to view a schedule.",
        emptyStateButtonText: "Join Tournament"
    },
    spieleList: {
        tischHeadline: "Table",
        buttonTextSpielen: "Play",
        buttonTextBearbeiten: "Edit",
    },
    listGruppenSpiele: {
        informationModalHeadline: "Almost there...",
        informationModalMessage: "wait a moment until the tournament starts to win the first games.",
        labelGruppe: "Group",
        labelTisch: "Table"
    },
    listGruppen: {
        labelGruppe: "Group",
        abbreviationDescriptionSpiele: "*G = Games",
        abbreviationDescriptionSiege: "*W = Win",
        abbreviationDescriptionNiederlage: "*L = Loss",
        abbreviationDescriptiongetroffeneBecher: "*CH = Cups hit",
        abbreviationDescriptionverloreneBecher: "*CL = Cups lost",
        abbreviationDescriptionBecherdifferenz: "*CD = Cup difference",
        abbreviationSpiele: "G",
        abbreviationSiege: "W",
        abbreviationNiederlage: "L",
        abbreviationgetroffeneBecher: "CH",
        abbreviationverloreneBecher: "CL",
        abbreviationBecherdifferenz: "CD",
        rankingHeadline: "Ranking"
    },
    listFinalSpiele: {
        teamOpen: "Team pending"
    },
    spielstandForm: {
        spielStandEditHeadline: "Change score",
        spielStandAddHeadline: "How many cups did you hit?",
        editSpielStandMessage: "Keep in mind, before you enter the cups you hit, that in the event of a tie, the game will continue until someone scores.",
        addSpielStandMessage: "Keep in mind, a tie is not an option.",
        spielStandHeadline2: "Hit cups:",
        addSuccessHeadline: "Score entered",
        editSuccessHeadline: "Score entered",
        erreichtePunkteTeam1Label: "Cups hit team 1",
        erreichtePunkteTeam2Label: "Cups hit team 2",
        erreichtePunkteTeam1Required: "Please enter a value",
        erreichtePunkteTeam2Required: "Please enter a value",
        erreichtePunkteTeam1Between: "cups are possible",
        erreichtePunkteTeam2Between: "cups are possible",
        erreichtePunkteTeam2NotSameValue: "The number of cups must be different",
        buttonTextAbbrechen: "Cancel",
        buttonTextAgree: "Confirm"
    },
    spielStarten: {
        headline: "Start game",
        buttonTextSpielStarten: "Start Game",
        buttonTextAbbrechen: "Cancel",
        buttonTextPause: "Pause",
        buttonTextPlayOn: "Play",
        buttonTextAgree: "Confirm",
        dialogTitleConfirm: "Cancel game?",
        buttonTextSpielBeenden: "Exit game",
        buttonTextSpielstandEintrage: "Enter score",
        eEmptyStateLabel: "Take your time...",
        emptyStateDescription: "Your game has no time limit.",
        buttonTextBack: "back"
    }
}
