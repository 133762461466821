import { SpringBackend } from '../config/backend-config'
export const modulGruppe = {
    state: {
        gruppen: [], //alle spiele eines turniers
        loadingStatus: false,
        isDataLoaded: false,
    },
    mutations: {
        SET_GRUPPEN_ARRAY(state, gruppen) {
            state.gruppen = gruppen;
        },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_DATA_LOADED_STATUS_GRUPPEN(state, isDataLoaded) {
            state.isDataLoaded = isDataLoaded;
        }
    },
    actions: {
        async getGruppenByTurnierID(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/gruppen?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_GRUPPEN_ARRAY', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_GRUPPEN', true);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
    },
    getters: {
        getGruppenDetails: (state) => {
            let gruppenSortedByTeamPlatzierung = []
            state.gruppen.forEach(gruppe => {
                gruppe.gruppenTeams = gruppe.gruppenTeams
                    .filter(t => t.platzierung != 0)
                    .sort((a, b) => 
                        a.platzierung - b.platzierung || 
                        a.team.teamName.localeCompare(b.team.teamName))
                    .concat(gruppe.gruppenTeams.filter(t => t.platzierung == 0))
                gruppenSortedByTeamPlatzierung.push(gruppe);
            });
            return gruppenSortedByTeamPlatzierung;
        },
        getGruppeByTeam: (state) => (teamID) => {
            return state.gruppen.filter(gruppe => 
                gruppe.gruppenTeams.some(teilnahme =>
                    teilnahme.team.teamID == teamID));
        },
        getGruppeByGruppenID: (state) => (gruppenID) => {
            return state.gruppen.filter(gruppe => gruppe.gruppenID == gruppenID)[0];
        }
    }
}