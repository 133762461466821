import { SpringBackend } from '../config/backend-config'
export const modulSpiel = {
    state: {
        spieleArray: [], //alle spiele eines turniers
        loadingStatus: false,
        isDataLoaded: false,
        spielArtReihenfolge: ["FINALE", "FINALE_PLATZ3", "FINALE_4", "FINALE_8", "FINALE_16", "FINALE_32", "FINALE_64", "FINALE_128", "FINALE_256","FINALE_512"],
        apiReponseErrorMessage: ''
    },
    mutations: {
        SET_SPIELE_ARRAY(state, spieleArray) {
            state.spieleArray = spieleArray.map(a => {
                a.spielErgebnisse.sort((a, b) => a.spielTeamID.teamID - b.spielTeamID.teamID);
                return a;
            });
        },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_DATA_LOADED_STATUS_SPIELE(state, isDataLoaded) {
            state.isDataLoaded = isDataLoaded;
        },
        SET_API_RESPONSE_ERROR_MESSAGE(state, apiReponseErrorMessage) {
            state.apiReponseErrorMessage = apiReponseErrorMessage;
        }
    },
    actions: {
        async getSpieleByTurnierID(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/spiele?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_SPIELE_ARRAY', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_SPIELE', true);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async putSpielergebnis(context, spielergebnisData, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put("/spielergebnis", spielergebnisData)
                .then(response => {
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    return error;
                })
        },

    },
    getters: {
        getSpiele: (state) => {
            return state.spieleArray;
        },
        getSpielByID: (state) => (spielID) => {
            return state.spieleArray.filter(spiel => spiel.spielID == spielID)[0];
        },
        getSpielReihenfolgeBySpielArt: (state) => (spielArt) => {
            const spielReihenfolge = state.spielArtReihenfolge.indexOf(spielArt);
            return spielReihenfolge === undefined ? 0 : (state.spielArtReihenfolge.length - spielReihenfolge);
        },
        getFinalSpiele: (state, getters) => {
            return state.spieleArray
                .filter(spiel => spiel.spielArt.spielArtEnum != getters.getSpielArt.GRUPPENSPIEL.name)
                .sort((a, b) => (getters.getSpielReihenfolgeBySpielArt(a.spielArt.spielArtEnum) - getters.getSpielReihenfolgeBySpielArt(b.spielArt.spielArtEnum)) || (a.tisch - b.tisch));
        },
        getGruppenSpiele: (state, getters) => {
            return state.spieleArray
                .filter(spiel => spiel.spielArt.spielArtEnum === getters.getSpielArt.GRUPPENSPIEL.name);
        },
        getGruppenSpieleByTischeAndGruppen: (state, getters) => (gruppen, tische) => {
            if (tische.length == 0 && gruppen.length == 0) {
                return getters.getGruppenSpiele
                    .sort((a, b) => (a.tisch - b.tisch) || (a.tischSpielNummer - b.tischSpielNummer))
            }
            if (tische.length == 0) {
                return getters.getGruppenSpiele
                    .filter(spiel => gruppen.indexOf(spiel.gruppenSpiele[0].gruppe.gruppenID) != -1)
                    .sort((a, b) => (a.tisch - b.tisch) || (a.tischSpielNummer - b.tischSpielNummer));
            }
            if (gruppen.length == 0) {
                return getters.getGruppenSpiele
                .filter(spiel => tische.indexOf(spiel.tisch) != -1)
                .sort((a, b) => (a.tisch - b.tisch) || (a.tischSpielNummer - b.tischSpielNummer));
            }
            
            return getters.getGruppenSpiele
                .filter(spiel => tische.indexOf(spiel.tisch) != -1)
                .filter(spiel => gruppen.indexOf(spiel.gruppenSpiele[0].gruppe.gruppenID) != -1)
                .sort((a, b) => (a.tisch - b.tisch) || (a.tischSpielNummer - b.tischSpielNummer));
        },
        //TODO
        getGruppenSimple: state => {
            let gruppenSpiele = state.spieleArray
            .filter(s => s.gruppenSpiele.length != 0)
            .map(s => s.gruppenSpiele[0].gruppe);
            
            let gruppen = []
            let gruppenIDs = []
            gruppenSpiele.forEach(gs => {
                if (gruppenIDs.find(g => g == gs.gruppenID) == undefined) {
                    gruppen.push(gs);
                    gruppenIDs.push(gs.gruppenID);
                }
            });
            return gruppen;
        },
        getTische: state => {
            let tischeSpiele = state.spieleArray
                .map(s => s.tisch);
            let tischeNummern = []
            tischeSpiele.forEach(t => {
                if (tischeNummern.find(n => n == t) == undefined) {
                    tischeNummern.push(t);
                }
            });
            return tischeNummern;
        },
    }
}