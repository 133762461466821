import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Startseite',
    component: () => import(/* webpackChunkName: "start" */ '../views/Startseite/Startseite.vue')
  },
  {
    path: '/home',
    name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue')
  },
  {
    path: '/registrieren',
    name: 'CreateAccount',
    component: () => import(/* webpackChunkName: "createAccount" */ '../views/Login/CreateAccount.vue')
  },
  {
    path: '/confirmAccount',
    name: 'ConfirmAccount',
    component: () => import(/* webpackChunkName: "ConfirmAccount" */ '../components/Login/ConfirmAccount.vue'),
    props: true
  },
  {
    path: '/forgotPasswordEmail',
    name: 'ForgotPasswordEmail',
    component: () => import(/* webpackChunkName: "ForgotPasswordEmail" */ '../components/Login/ForgotPasswordEmail.vue'),
  },
  {
    path: '/forgotPasswordConfirm',
    name: 'ForgotPasswordConfirm',
    component: () => import(/* webpackChunkName: "ForgotPasswordConfirm" */ '../components/Login/ForgotPasswordConfirm.vue'),
    props: true
  },
  {
    path: '/createTurnier',
    name: 'CreateTurnier',
    component: () => import(/* webpackChunkName: "createTurnier" */ '../views/Turnier/CreateTurnier.vue')
  },
  {
    path: '/turniere',
    name: 'ListTurniere',
    component: () => import(/* webpackChunkName: "turniere" */ '../views/Turnier/ListTurniere.vue')
  },
  {
    path: '/turnier/:turnierID',
    name: 'TurnierDetail',
    component: () => import(/* webpackChunkName: "turnierDetail" */ '../views/Turnier/TurnierDetail.vue'),
    props: true,
  },
  {
    path: '/turnierEdit/:turnierID',
    name: 'EditTurnier',
    component: () => import(/* webpackChunkName: "turnierEdit" */ '../views/Turnier/EditTurnier.vue'),
    props: true
  },
  {
    path: '/teams/:turnierID',
    name: 'ListTeams',
    component: () => import(/* webpackChunkName: "teams" */ '../views/Teams/ListTeams.vue'),
    props: true
  },
  {
    path: '/spiele',
    name: 'SpieleUebersicht',
    component: () => import(/* webpackChunkName: "teams" */ '../views/Spiele/SpieleUebersicht.vue'),
  },
  {
    path: '/spiele/turnier/:turnierID',
    name: 'Spiele',
    component: () => import(/* webpackChunkName: "spiele" */ '../views/Spiele/Spiele.vue'),
    props: true,
    children: [
      {
        path: 'gruppenSpiele',
        name: 'ListGruppenSpiele',
        component: () => import(/* webpackChunkName: "gruppenSpiele" */ '../views/Spiele/ListGruppenSpiele.vue'),
        props: true
      },
      {
        path: 'finalSpiele',
        name: 'ListFinalSpiele',
        component: () => import(/* webpackChunkName: "finalSpiele" */ '../views/Spiele/ListFinalSpiele.vue'),
        props: true
      },
      {
        path: 'gruppen',
        name: 'ListGruppen',
        component: () => import(/* webpackChunkName: "gruppen" */ '../views/Turnier/ListGruppen.vue'),
        props: true
      },
      {
        path: 'ranking',
        name: 'TurnierRanking',
        component: () => import(/* webpackChunkName: "ranking" */ '../views/Turnier/TurnierRanking.vue'),
        props: true
      },
      {
        path: 'spiele',
        name: 'ListSingleSpiele',
        component: () => import(/* webpackChunkName: "singleSpiele" */ '../views/Spiele/ListSingleSpiele.vue'),
        props: true
      }
    ]
  },
  {
    path: '/spielen/:turnierID/:spielID/',
    name: 'PlayGame',
    component: () => import(/* webpackChunkName: "spielen" */ '../views/Spiele/PlayGame.vue'),
    props: true
  },
  {
    path: '/spieler',
    name: 'SpielerDetail',
    component: () => import(/* webpackChunkName: "spielerDetail" */ '../views/Spieler/SpielerDetail.vue'),
    props: true
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "Impressum" */ '../components/Utils/Impressum.vue'),
    props: true
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "Datenschutz" */ '../components/Utils/Datenschutz.vue'),
    props: true
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "Kontakt" */ '../components/Utils/Kontakt.vue'),
    props: true
  },
  {
    path: '/404',
    alias: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router

router.beforeEach(async (to, from, next) => {
  const publicPages = ['Login', 'CreateAccount', 'Startseite', 'Impressum', 'Datenschutz', 'ConfirmAccount', 'ForgotPasswordEmail', 'ForgotPasswordConfirm'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.token;
  const requireGuestRole = ['ListGruppenSpiele', 'ListFinalSpiele', 'ListGruppen', 'ListTeams', 'TurnierRanking', 'ListSingleSpiele'] 
  const requireAdminRole = ['EditTurnier']
  const showNotCookieModal = ['Startseite', 'Impressum', 'Datenschutz']

  if (authRequired && !loggedIn) {
    next({ name: 'Startseite'});
  }

  if (!showNotCookieModal.includes(to.name)) {
    if (localStorage.getItem("cookieModal") != 'false') {
      store.commit("SET_SHOW_COOKIE_MODAL", {
        show: true
      });
    }
  } else {
    store.commit("SET_SHOW_COOKIE_MODAL", {
      show: false
    });
  }

  if (requireAdminRole.includes(to.name)) {
    if (Object.entries(store.getters.getSpielerData).length == 0) {
      await store.dispatch('getSpielerData');
    }
    if (!store.getters.isSpielerTurnierAdmin(to.params.turnierID)) {
      next({name: 'Home'});
    }
  }

  if (requireGuestRole.includes(to.name)) {
    if (Object.entries(store.getters.getSpielerData).length == 0) {
      await store.dispatch('getSpielerData');
    }
    if (!store.getters.hasSpielerTurnierGastRolle(to.params.turnierID)) {
      next({name: 'Home'});
    }
  }

  if (to.name === 'PlayGame') {
    if (Object.entries(store.getters.getSpielerData).length == 0) {
      await store.dispatch('getSpielerData');
    }
    
    if (store.getters.getTeams.length == 0) {
      await store.dispatch('getTeamsByTurnierID', to.params.turnierID);
    }
    
    if (store.getters.getSpiele.length == 0) {
      await store.dispatch('getSpieleByTurnierID', to.params.turnierID);
    }
    
    if (Object.keys(store.getters.getTurnier).length == 0) {
      await store.dispatch('getSingleTurnier', to.params.turnierID);
    }

    const spiel = store.getters.getSpielByID(to.params.spielID);
    const playerTeamNumber = store.getters.getPlayerTeamNumber(store.getters.getSpielerID);
    if ((spiel.spielErgebnisse[0].spielTeamID.teamID != playerTeamNumber && spiel.spielErgebnisse[1].spielTeamID.teamID != playerTeamNumber) && 
      !store.getters.isSpielerTurnierAdmin(to.params.turnierID)) {
        console.log("NAV HOME")
      next({ name: 'Home' });
    }
  }

  next()

  // this.$route.params.turnierID
});