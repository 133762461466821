<template>
  <div class="information-modal">
    <transition
      enter-active-class="zoomInFade"
      leave-active-class="zoomOutFade">
      <md-dialog :md-active.sync="modal.show" :md-fullscreen=false :md-close-on-esc=false :md-click-outside-to-close=false>
        <div class="dialog-content-information-modal">
          <p class="headline">{{lang.cookie.headline}}</p>
          <p class="message">{{lang.cookie.message}}</p>
        </div>
        <md-dialog-actions>
          <md-button class="md-primary md-raised md-filled" @click="confirmCookies()">{{lang.cookie.buttonText | uppercase}}</md-button>
        </md-dialog-actions>
        <ul class="ul-unstyled impressum">
            <li><router-link :to="{ name: 'Impressum' }">{{lang.startseite.footerLinkText1}}</router-link></li>
            <li><router-link :to="{ name: 'Datenschutz' }">{{lang.startseite.footerLinkText2}}</router-link></li>
        </ul>
      </md-dialog>
    </transition>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'

export default {
  name: 'CookieModal',
  filters: {
    uppercase: function(v) {
      return v.toUpperCase();
    }
  },
  methods: {
    confirmCookies() {
      store.commit("SET_SHOW_COOKIE_MODAL", {
        show: false
      });
      localStorage.setItem("cookieModal", false);
    }
  },
  computed: {
    modal: {
      get: function () {
        return store.getters.getCookieModal;
      },
      set: function (newValue) {}
    },
    lang() {
      return store.getters.getLanguage;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/base';

.md-dialog .md-dialog-container {
  max-width: 320px;
  width: 320px;
}

.dialog-content-information-modal {
  padding: 25px 20px 0 20px;
}

.headline {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3.4rem;
  margin-bottom: 15px;
  max-width: 320px
}

.message {
  margin-bottom: 15px;
  max-width: 320px
}

.md-button {
  padding: 0 20px;
  margin-bottom: 10px;
}

.impressum {
  display: block;
  margin: 5px auto 30px;
  
  li {
    display: inline-block;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: rgb(158, 157, 157) !important;
    font-size: 1.3rem;
  }
}

@media (min-width: $screen-xs2) {
  .headline {
    max-width: 450px;
    padding: 0 20px;
  }

  .message {
    font-size: 1.5rem;
    padding: 0 20px;
    max-width: 450px;
  }
}
</style>
