import { SpringBackend } from '../config/backend-config'
export const modulTeilnahme = {
    state: {
        teilnahmen: [], //alle teilnahmen eines turniers
        loadingStatus: false,
        isDataLoaded: false,
    },
    mutations: {
        SET_TEILNAHMEN_ARRAY(state, teilnahmen) {
            state.teilnahmen = teilnahmen;
        },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_DATA_LOADED_STATUS_TEILNAHMEN(state, isDataLoaded) {
            state.isDataLoaded = isDataLoaded;
        }
    },
    actions: {
        async getTeilnahmenByTurnierID(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/teilnahme?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_TEILNAHMEN_ARRAY', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_TEILNAHMEN', true);
                    return response;
                })
                .catch(function (error) {
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
    },
    getters: {
        getTeilnahmen: (state) => {
            return state.teilnahmen
                    .filter(t => t.platzierung != 0)
                    .sort((a, b) => 
                        a.platzierung - b.platzierung || 
                        a.team.teamName.localeCompare(b.team.teamName))
                    .concat(state.teilnahmen.filter(t => t.platzierung == 0));
        }
    }
}