<template>
  <div id="app">
    <router-view/>
    <InformationModal/>
    <CookieModal/>
    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
      <span>{{lang.offline.snackbarMessage}}</span>
      <md-button class="md-primary" @click="showSnackbar = false">{{lang.offline.snackbarButtonText}}</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import store from '@/store/index'
import { VueOfflineMixin } from 'vue-offline'
import InformationModal from './components/Utils/InformationModal'
import CookieModal from './components/Utils/CookieModal'

export default {
  name: 'App',
  mixins: [VueOfflineMixin],
  components: {
    InformationModal,
    CookieModal
  },
  metaInfo() {
    return {
      title: this.lang.meta.appTitle,
      meta: [
        { property: 'description', content: this.lang.meta.description },
        { property: 'og:title', content: this.lang.meta.ogTitle },
        { property: 'og:url', content: this.lang.meta.ogUrl },
        { property: 'og:description', content: this.lang.meta.ogDescription },
        { property: 'og:image', content: 'https://beerpongfriends.com/img/beerpong-images/beer-pong-icon-red.svg' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'de' },
        { property: 'og:locale:alternate', content: 'en' },
        { property: 'og:site_name', content: this.lang.meta.ogSiteName },
      ],
      link: [
        { rel: 'manifest', href: this.lang.name == 'en' ? '/en.site.webmanifest' : '/site.webmanifest' },
      ]
    }
 },
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: true,
      installEvent: null,
      updateEvent: null,
      watchModal: null,
    }
  },
  created() {
    //trigger install pwa modal
    window.addEventListener("beforeinstallprompt", e => {
      if (!this.getLocalStorageVariable("installEvent")) {
          store.commit("SET_SHOW_INFORMATION_DIALOG", {
            show: true,
            headline: this.lang.pwa.informationModalHeadlineInstall,
            message: this.lang.pwa.informationModalMessageInstall
          });
          this.setLocalStorageVariable("installEvent", "true");
          this.installEvent = e;
      } else {
        e.prompt();
      }
    }, {once: true});
    
    //trigger update pwa modal
    window.addEventListener('swUpdated', e => {
        store.commit("SET_SHOW_INFORMATION_DIALOG", {
          show: true,
          headline: this.lang.pwa.informationModalHeadlineUpdate,
          message: this.lang.pwa.informationModalMessageUpdate
        });
        this.updateEvent = e.detail;
    }, { once: true });

    this.watchModal = store.watch(
      (state) => state.informationDialog,
      (newValue, oldValue) => {
        if (!newValue.show && oldValue.headline == this.lang.pwa.informationModalHeadlineInstall) {
          this.modalInstallButtonClicked();
        }
        if (!newValue.show && oldValue.headline == this.lang.pwa.informationModalHeadlineUpdate) {
          this.modalUpdateButtonClicked();
        }
      },
    );

    if (this.getLocalStorageVariable("language")) {
      store.commit("SET_LANGUAGE", this.getLocalStorageVariable("language"));
    } else {
      if (navigator.language.includes("de")) {
        store.commit("SET_LANGUAGE", "de");
      } else if (navigator.language.includes("en")) {
        store.commit("SET_LANGUAGE", "en");
      } else {
        store.commit("SET_LANGUAGE", "de");
      }
    }
  },
  mounted () {
    this.$on('offline', () => {
      this.showSnackbar = true;
    })
  },
  methods: {
    getLocalStorageVariable(localStorageVariableName) {
      return localStorage.getItem(localStorageVariableName) ? localStorage.getItem(localStorageVariableName) : '';
    },
    setLocalStorageVariable(localStorageVariableName, data) {
      localStorage.setItem(localStorageVariableName, data);
    },
    modalInstallButtonClicked() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then(result => {
        this.installEvent = null;
      })
    },
    modalUpdateButtonClicked() {
      // make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.updateEvent || !this.updateEvent.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.updateEvent.waiting.postMessage({ type: 'SKIP_WAITING' });
      this.updateEvent = null;
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
  },
  beforeDestroy() {
    this.watchModal();
  },
}
</script>

<style lang="scss">

@import 'assets/scss/base';
@import 'assets/scss/robotoFont';
@import "~vue-material/dist/theme/engine";

@include md-register-theme("default", (
  primary: $brand-primary,
  accent: $brand-secondary,
  theme: light
));
@import "~vue-material/dist/theme/all";

@import 'assets/scss/common';
@import 'assets/scss/typografie';
@import 'assets/scss/buttons';
@import 'assets/scss/forms';
@import 'assets/scss/turnier/turnierDetail';
@import 'assets/scss/turnier/turnierListe';
@import 'assets/scss/turnier/turnierForm';
@import 'assets/scss/turnier/turnierRanking';
@import 'assets/scss/spiele/spieleList';
@import 'assets/scss/animation';
@import 'assets/scss/login/loginForms';
@import 'assets/scss/components/components';
@import 'assets/scss/utils/unterseite';


</style>
