import { SpringBackend } from '../config/backend-config'

export const modulTurnier = {
    state: {
        turniereArray: [],
        isLastDataFetchedEmpty: true,
        loadingStatus: false,
        isDataLoaded: false,
        apiReponseErrorMessage: '',
        apiReponseErrorData: [],
        turnier: {}
    },
    mutations: {
        SET_TURNIERE_ARRAY(state, turniereArray) {
            state.turniereArray = turniereArray;
        },
        SET_IS_LAST_DATA_FETCHED_EMPTY(state, turniereArray) {
            state.isLastDataFetchedEmpty = turniereArray.length == 0;
        },
        ADD_ELEMENT_TO_TURNIERE_ARRAY(state, turniereArray) {
            state.turniereArray = [...state.turniereArray, ...turniereArray];
        },
        SET_TURNIER(state, turnier) {
            turnier.spielArtTurnier = turnier.spielArtTurnier.sort((a, b) => a.spielArt.spielArtID - b.spielArt.spielArtID);
            state.turnier = turnier;
        },
        ADD_TURNIER(state, turnier) {
            state.turniereArray.push(turnier);
        },
        DELETE_TURNIER(state, turnierID) {
            let index = state.turniereArray.findIndex((t) => t.turnierID == turnierID);
            if (index != -1) {
                state.turniereArray.splice(index, 1);
            }
        },
        UPDATE_TURNIER(state, turnier) {
            //Find index of specific object using findIndex method.
            let oldTurnierIndex = state.turniereArray.findIndex((t => t.turnierID == turnier.turnierID));
            state.turniereArray[oldTurnierIndex] = turnier;
        },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_DATA_LOADED_STATUS_TURNIER(state, isDataLoaded) {
            state.isDataLoaded = isDataLoaded;
        },
        SET_API_RESPONSE_ERROR_MESSAGE(state, apiReponseErrorMessage) {
            state.apiReponseErrorMessage = apiReponseErrorMessage;
        },
        SET_API_RESPONSE_ERROR_DATA(state, apiReponseErrorData) {
            state.apiReponseErrorData = apiReponseErrorData;
        }
    },
    //API Calls
    actions: {
        async getTurniere(context, {stadt, turnierStatus, page, searchWord}) {
            const isTurnierClosed = turnierStatus == "active" ? false : true;
            context.commit('SET_DATA_LOADED_STATUS_TURNIER', false);
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/turnier?page=${page}&stadt=${stadt}&isTurnierClosed=${isTurnierClosed}&searchWord=${searchWord}`)
                .then(response => {
                    context.commit('ADD_ELEMENT_TO_TURNIERE_ARRAY', response.data);
                    context.commit('SET_IS_LAST_DATA_FETCHED_EMPTY', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_TURNIER', true);
                    return response;
                })
                .catch(error => {
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async getSingleTurnier(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/turnier?turnierID=${turnierID}`)
                .then(response => { 
                    context.commit('SET_TURNIER', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_TURNIER', true);
                    return response;
                })
                .catch(error => {
                    context.commit('SET_LOADING_STATUS', false);
                    context.commit('SET_DATA_LOADED_STATUS_TURNIER', true);
                    return error;
                })
        },
        async postTurnier(context, turnier) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post('/turnier', turnier)
                .then(response => {
                    context.commit('ADD_TURNIER', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async putTurnier(context, turnier) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put(`/turnier?turnierID=${turnier.turnierID}`, turnier)
                .then(response => {
                    context.commit('UPDATE_TURNIER', response.data);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async deleteTurnier(context, turnier) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.delete(`/turnier?turnierID=${turnier.turnierID}`)
                .then(response => {
                    context.commit('DELETE_TURNIER', turnier.turnierID);
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                });
        },
        async createTurnierPlanGruppenSystem(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/createTurnierPlanGruppenSystem?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async createTurnierPlanKoSystem(context, turnierID) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/createTurnierPlanKoSystem?turnierID=${turnierID}`)
                .then(response => {
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        },
        async startFinalPhase(context, { turnierID, teamsWeiterSamePlatzierung }) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/startFinalPhase?turnierID=${turnierID}`, teamsWeiterSamePlatzierung)
                .then(response => {
                    context.commit('SET_LOADING_STATUS', false);
                    return response;
                })
                .catch(error => {
                    const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                        error.response.data.message : store.state.lang.error.defaultMessage;
                    const errorData = error.response && error.response.data.hasOwnProperty('data') ?
                        error.response.data.data : [];
                    context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                    context.commit('SET_API_RESPONSE_ERROR_DATA', errorData);
                    context.commit('SET_LOADING_STATUS', false);
                    return error;
                })
        }
    },
    getters: {
        filterTurnierByName: (state) => (letters) => {
            return state.turniereArray.filter(t => {
                return t.turnierName.toLowerCase().includes(letters.toLowerCase())
            })
        },
        getTurniere: (state) => {
            return state.turniereArray;
        },
        getTurnier: (state) => {
            return state.turnier;
        },
        isLastTurnierDataFetchedEmpty: (state) => {
            return state.isLastDataFetchedEmpty;
        },
        isTurnierBeendet: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.BEENDET : false;
        }, 
        isTurnierGestartet: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.GESTARTET : false;
        }, 
        isGruppenphaseBeendet: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.GRUPPENPHASE_BEENDET : false;
        }, 
        isTurnierStatusErstellt: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.ERSTELLT : false;
        },
        isTurnierStatusTurnierPlanErstellt: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.TURNIERPLAN_ERSTELLT : false;
        },
        isFinalphaseGestartet: (state, getters) => {
            return state.turnier.turnierStatus ? state.turnier.turnierStatus.status == getters.getTurnierStatus.FINALPHASE_GESTARTET : false;
        },
        getTurniereClosed: (state, getters) => {
            return state.turniereArray.filter(t => t.turnierStatus.status == getters.getTurnierStatus.BEENDET);
        },
        getTurniereActive: (state, getters) => {
            return state.turniereArray.filter(t => !t.turnierStatus.status == getters.getTurnierStatus.BEENDET);
        },
        getTimeByTurnierIDAndSpielArt: (state) => (spielArtID) => {
            return state.turnier.spielArtTurnier ? state.turnier.spielArtTurnier.find(s => s.spielArt.spielArtID == spielArtID).spielZeit : 0; 
        }
    },
}