import { SpringBackend } from '../config/backend-config'

export const modulSpieler = {
    state: {
        spieler: {},
        loadingStatus: false,
        apiReponseErrorMessage: '',
        token: localStorage.token || '',
        activeTurniereArray: [],
        closedTurniereArray: [],
        activeGruenderTurniereArray: [],
        closedGruenderTurniereArray: [],
        isSpielerDataLoaded: false,
        isActiveTurniereArrayLoaded: false,
        isClosedTurniereArrayLoaded: false,
        isGruenderTurniereArrayLoaded: false
    },
    mutations: {
        SET_SPIELER(state, spieler) {
            state.spieler = spieler;
        },
        ADD_SPIELER_TURNIER_ROLLE(state, spielerTurnierRolle) {
            state.spieler.spielerTurnierRolle.push(spielerTurnierRolle);
        },
        SET_API_RESPONSE_ERROR_MESSAGE(state, message) {
            state.apiReponseErrorMessage = message;
        },
        SET_LOADING_STATUS(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_ACTIVE_TURNIERE(state, turniereArray) {
            state.activeTurniereArray = turniereArray.sort(function (a, b) {
                return new Date(b.datum) - new Date(a.datum);
            });
        },
        SET_CLOSED_TURNIERE(state, turniereArray) {
            state.closedTurniereArray = turniereArray.sort(function (a, b) {
                return new Date(b.datum) - new Date(a.datum);
            });
        },
        SET_ACTIVE_GRUENDER_TURNIERE(state, turniereArray) {
            state.activeGruenderTurniereArray = turniereArray.sort(function (a, b) {
                return new Date(b.datum) - new Date(a.datum);
            });
        },
        SET_CLOSED_GRUENDER_TURNIERE(state, turniereArray) {
            state.closedGruenderTurniereArray = turniereArray.sort(function (a, b) {
                return new Date(b.datum) - new Date(a.datum);
            });
        },
        SET_SPIELER_DATA_IS_LOADED_STATUS(state, isDataLoaded) {
            state.isSpielerDataLoaded = isDataLoaded;
        },
        SET_ACTIVE_TURNIERE_DATA_IS_LOADED_STATUS(state, isDataLoaded) {
            state.isActiveTurniereArrayLoaded = isDataLoaded;
        },
        SET_CLOSED_TURNIERE_DATA_IS_LOADED_STATUS(state, isDataLoaded) {
            state.isClosedTurniereArrayLoaded = isDataLoaded;
        },
        SET_GRUENDER_TURNIER_DATA_IS_LOADED_STATUS(state, isDataLoaded) {
            state.isGruenderTurniereArrayLoaded = isDataLoaded;
        },
    },
    //API Calls
    actions: {
        async getSpielerData(context) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get("/spielerData")
            .then(response => {
                context.commit('SET_SPIELER', response.data);
                context.commit('SET_LOADING_STATUS', false);
                context.commit('SET_SPIELER_DATA_IS_LOADED_STATUS', true);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async postSpieler(context, { newSpieler, token, datenschutzConfirm}) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/register?captcha=${token}&datenschutzZustimmung=${datenschutzConfirm}`, newSpieler)
            .then(response => {
                context.commit('SET_SPIELER', response.data);
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async putSpieler(context, spieler) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put("/spielerData", spieler)
            .then(response => {
                context.commit('SET_SPIELER', response.data)
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async putSpielerPasswort(context, passwortData) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.put("/changePasswort", passwortData)
            .then(response => {
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async confirmAccount(context, token) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/confirmAccount?token=${token}`, null)
            .then(response => {
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async forgotPasswordEmail(context, {token, email}) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/forgotPasswordEmail?captcha=${token}&email=${email}`, null)
            .then(response => {
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async forgotPasswordConfirm(context, passwortData) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post("/forgotPasswordConfirm", passwortData)
            .then(response => {
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async login(context, auth) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post("/login", auth)
            .then(response => {
                const token = response.data.jwt;
                context.commit('SET_TOKEN', token);
                context.commit('SET_LOADING_STATUS', false);
                localStorage.token = token;
                SpringBackend.defaults.headers.common['Authorization'] = "Baerer " + localStorage.token;
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                context.commit('SET_TOKEN', "");
                localStorage.token = "";
                return error;
            })
        },
        logout(context) {
            context.commit("SET_TOKEN", "");
            localStorage.token = "";
            delete SpringBackend.defaults.headers.common['Authorization']
        },
        async getActiveTurniere(context) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get("/turnier?isTurnierClosed=false")
            .then(response => {
                context.commit('SET_ACTIVE_TURNIERE', response.data);
                context.commit('SET_LOADING_STATUS', false);
                context.commit('SET_ACTIVE_TURNIERE_DATA_IS_LOADED_STATUS', true);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async getClosedTurniere(context) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get("/turnier?isTurnierClosed=true")
            .then(response => {
                context.commit('SET_CLOSED_TURNIERE', response.data);
                context.commit('SET_LOADING_STATUS', false);
                context.commit('SET_CLOSED_TURNIERE_DATA_IS_LOADED_STATUS', true);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async getGegruendeteTurniere(context, isTurnierClosed) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.get(`/turnier/gruender?isTurnierClosed=${isTurnierClosed}`)
            .then(response => {
                if (isTurnierClosed) {
                    context.commit('SET_CLOSED_GRUENDER_TURNIERE', response.data);
                } else {
                    context.commit('SET_ACTIVE_GRUENDER_TURNIERE', response.data);
                }
                context.commit('SET_LOADING_STATUS', false);
                context.commit('SET_GRUENDER_TURNIER_DATA_IS_LOADED_STATUS', true);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
        async postTurnierRolleGast(context, data) {
            context.commit('SET_LOADING_STATUS', true);
            return await SpringBackend.post(`/turnierRolleGast?turnierID=${data.turnierID}&turnierPasswort=${data.turnierPasswort}`)
            .then(response => {
                context.commit('SET_LOADING_STATUS', false);
                return response;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data.hasOwnProperty('message') ?
                    error.response.data.message : store.state.lang.error.defaultMessage;
                context.commit('SET_API_RESPONSE_ERROR_MESSAGE', errorMessage);
                context.commit('SET_LOADING_STATUS', false);
                return error;
            })
        },
    },
    getters: {
        getAuthToken: (state) => {
            return state.token;
        },
        getSpielerData: (state) => {
            return state.spieler;
        },
        getSpielerID: (state) => {
            return state.spieler.spielerID;
        },
        isSpielerTurnierAdmin: (state, getters) => (turnierID) => {
            if (state.spieler.spielerTurnierRolle == null) {
                return false;
            } else {
                const turnierIDFind = state.spieler.spielerTurnierRolle
                    .find(t => t.turnierSpielerID.turnierID == turnierID && t.spielerRolle.rollenBezeichnung === getters.getSpielerRolle.GRUENDER);
                return turnierIDFind != undefined ? true : false;
            }
        },
        hasSpielerTurnierGastRolle: (state) => (turnierID) => {
            if (state.spieler.spielerTurnierRolle == null) {
                return false;
            } else {
                const turnierIDFind = state.spieler.spielerTurnierRolle
                    .find(t => 
                            t.turnierSpielerID.turnierID == turnierID);
                return turnierIDFind != undefined ? true : false;
            }
        },
        getActiveTurniereArray: (state) => {
            return state.activeTurniereArray
                .concat(state.activeGruenderTurniereArray)
                .sort((a, b) => new Date(b.datum) - new Date(a.datum));
        },
        getActiveTurniereArrayNotGruender: (state, getters) => {
            if (state.activeTurniereArray === undefined || state.activeTurniereArray.length == 0) {
                return [];
            } else {
                return state.activeTurniereArray
                    .filter(turnier => turnier.spielerTurnierRolle
                        .some(tg => tg.turnierSpielerID.spielerID == state.spieler.spielerID && tg.spielerRolle.rollenBezeichnung != getters.getSpielerRolle.GRUENDER));
            }
        },
        getClosedTurniereArrayNotGruender: (state, getters) => {
            if (state.closedTurniereArray === undefined || state.closedTurniereArray.length == 0) {
                return [];
            } else {
                return state.closedTurniereArray
                    .filter(turnier => turnier.spielerTurnierRolle
                        .some(tg => tg.turnierSpielerID.spielerID == state.spieler.spielerID && tg.spielerRolle.rollenBezeichnung != getters.getSpielerRolle.GRUENDER));
            }
        },
        getClosedTurniereArray: (state) => {
            return state.closedTurniereArray
                .concat(state.closedGruenderTurniereArray)
                .sort((a, b) => new Date(b.datum) - new Date(a.datum));
        },
        getActiveGruenderTurniereArray: (state) => {
            return state.activeGruenderTurniereArray;
        },
        getClosedGruenderTurniereArray: (state) => {
            return state.closedGruenderTurniereArray;
        },
        getGruenderTurniereArray: (state) => {
            return state.closedGruenderTurniereArray
                .concat(state.activeGruenderTurniereArray)
                .sort((a, b) => new Date(b.datum) - new Date(a.datum));
        },
        getActiveConcatTurniere: (state, getters) => {
            if (getters.getActiveTurniereArrayNotGruender === undefined ||
                getters.getActiveGruenderTurniereArray === undefined) {
                return [];
            } else {
                return getters.getActiveTurniereArrayNotGruender
                    .concat(getters.getActiveGruenderTurniereArray)
                    .sort((a, b) => new Date(b.datum) - new Date(a.datum));
            }
        },
        getClosedConcatTurniere: (state, getters) => {
            if (getters.getClosedTurniereArrayNotGruender === undefined ||
                getters.getClosedGruenderTurniereArray === undefined) {
                return [];
            } else {
                return getters.getClosedTurniereArrayNotGruender
                    .concat(getters.getClosedGruenderTurniereArray)
                    .sort((a, b) => new Date(b.datum) - new Date(a.datum));
            }
        }
    },
}