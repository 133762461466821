import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueOffline from 'vue-offline'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { MdContent, MdRipple, MdButton, MdField, MdDialog, MdDatepicker, MdDialogConfirm, MdDialogPrompt, MdProgress, MdSteppers, MdCheckbox, MdSpeedDial, MdBottomBar, MdIcon, MdAvatar, MdList, MdMenu, MdTabs, MdTable, MdDivider, MdEmptyState, MdSnackbar, MdSwitch} from 'vue-material/dist/components'
// import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(MdContent)
Vue.use(MdRipple)
Vue.use(MdButton)
Vue.use(MdField)
Vue.use(MdDialog)
Vue.use(MdDialogConfirm)
Vue.use(MdDialogPrompt)
Vue.use(MdDatepicker)
Vue.use(MdProgress)
Vue.use(MdSteppers)
Vue.use(MdCheckbox)
Vue.use(MdBottomBar)
Vue.use(MdIcon)
Vue.use(MdSpeedDial)
Vue.use(MdAvatar)
Vue.use(MdList)
Vue.use(MdMenu)
Vue.use(MdTabs)
Vue.use(MdTable)
Vue.use(MdDivider)
Vue.use(MdEmptyState)
Vue.use(MdSnackbar)
Vue.use(MdSwitch)
// Vue.use(VueMaterial)

Vue.use(VueOffline, {
  mixin: false
})

new Vue({
  created() {
    AOS.init()
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
