export const langDE = {
    name: "de",
    meta: {
        appTitle: "Beer Pong Friends - Turnier Planer App",
        // description: "HIER MUSS NOCH EIN TEXT STEHEN!!!!!!!!!!!!!!! - Beer Pong, Friends, App, Turnier, Planer, Manager, Red Cups, Events",
        description: "Spare Zeit mit dem smarten Beer Pong Turnier Planer für dein Smartphone oder Laptop.",
        ogTitle: "Beer Pong Friends - Turnier Planer",
        ogUrl: "https://beerpongfriends.com/",
        ogDescription: "Spare Zeit mit dem smarten Beer Pong Turnier Planer für dein Smartphone oder Laptop.",
        ogSiteName: "Beer Pong Friends Turnier Planer"
    },
    notFound: {
        label: "Not Found",
        description: "Oops wir konnten diese Seite nicht finden.",
        buttonText: "Zurück zur Start"
    },
    pwa: {
        informationModalHeadlineInstall: "Als App hinzufügen?",
        informationModalMessageInstall: "NEU: Du kannst den Beer Pong Turnier Planer als App zum Startbildschirm hinzufügen und musst endlich nicht mehr den Browser nutzen.",
        informationModalMessageInstallIOS: "NEU: Klick auf das Share Icon in der Fußleiste und dann auf \"Zum Home-Bildschirm\".",
        informationModalHeadlineUpdate: "Update verfügbar",
        informationModalMessageUpdate: "Klick auf Okay und lade die Seite neu.",
    },
    modal: {
        buttonTextOkay: "Okay",
        buttonTextAgree: "Bestätigen",
        buttonTextCancel: "Abbrechen",
        errorHeadline: "Fehler"
    },
    captcha: {
        expired: "Beim Prüfen des Captchas ist ein Fehler aufgetreten, probiere es bitte erneut.",
        error: "Beim Prüfen des Captchas ist ein Fehler aufgetreten, probiere es bitte erneut.",
        required: "Bitte fülle das Captcha aus."
    },
    cookie: {
        headline: "Die Website verwendet notwendige Cookies",
        message: "Diese Webseite verwendet notwendige Cookies – nähere Informationen dazu und zu deinen Rechten als Benutzer findest du in der Datenschutzerklärung am Ende der Seite. Klicke auf „Cookies Akzeptieren“, um die Website besuchen zu können",
        buttonText: "Cookies Akzeptieren",
        linkImpressum: "Impressum",
        linkDatenschutz: "Datenschutz",
    },
    offline: {
        label: "Internetverbindung verloren",
        description: "Oops du bist gerade offline. Die App ist nun leider nur eingeschränkt nutzbar.",
        buttonText: "Zurück zur Start",
        snackbarMessage: "Oops die Internetverbindung ist verloren gegangen. Die App ist leider nur eingeschränkt nutzbar.",
        snackbarButtonText: "OKAY",
        offlineErrorMessage: "Eine Internetverbindung ist notwendig."
    },
    error: {
        defaultMessage: "Es ist ein unerwarteter Fehler aufgetreten.",
        formValidationHeadline: "Bitte prüfe deine Eingaben",
        formValidationMessage: "Die eingegeben Daten entsprechen nicht den Vorgaben"
    },
    bottomBar: {
        home: "Home",
        turniere: "Turniere",
        spielPlan: "Spielplan",
        spielerDetail: "Spieler"
    },
    spielart: {
        GRUPPENSPIEL: "Gruppenspiele",
        FINALE_512: "256tel Finale",
        FINALE_256: "128tel Finale",
        FINALE_128: "64tel Finale",
        FINALE_64: "32tel Finale",
        FINALE_32: "16tel Finale",
        FINALE_16: "Achtelfinale",
        FINALE_8: "Viertelfinale",
        FINALE_4: "Halbfinale",
        FINALE_PLATZ3: "Platz 3",
        FINALE: "Finale"
    },
    spielmodus: {
        GRUPPEN_SYSTEM: "Gruppensystem",
        KO_SYSTEM: "K.o.-System"
    },
    datenschutz: {
        header1: "Datenschutz"
    },
    kontakt: {
        header1: "Kontakt",
        paragraph: "Kontaktiere uns für Vorschläge oder Anmerkungen gern unter"
    },
    startseite: {
        slickHeader1: "Spare Zeit",
        slickParagraph1: "Keine Spielplanung und aufwendige Excel Tabellen mehr notwendig. Die Automatische Generierung des Turnierplans kann dir den Abend retten.",
        slickHeader2: "Lehn dich zurück",
        slickParagraph2: "Du hast als Veranstalter keinen Stress mehr, denn die Spieler können ganz einfach ihren Spielstand selbst eintragen.",
        slickHeader3: "Alles auf einem Blick",
        slickParagraph3: "Datum, Startzeit, Spielzeiten und Anzahl der Teams sind für dich und deine Freunde jeder Zeit sichtbar.",
        slickHeader4: "Live Spielplan",
        slickParagraph4: "Die Spielreihenfolge und die Platzierungen der Teams werden live aktualisiert.",
        navLoginText: "Login",
        navStartText: "Jetzt Starten",
        header1: "Beer Pong Turnier schnell geplant",
        header2: "Spare Zeit mit dem smarten Beer Pong Turnier Planer für dein Smartphone oder Laptop. Turniere und Spielpläne werden für dich automatisch erstellt.",
        headerButtonText: "Jetzt Starten",
        imgAlt1: "Mobile Startansicht",
        imgAlt2: "Turnierdetail Ansicht",
        imgAlt3: "Teams Ansicht",
        imgAlt4: "Spielplan Ansicht",
        imgAlt5: "Spiel Ansicht",
        imgAlt6: "Gruppen Ansicht",
        article1Heading: "Features die das Leben vereinfachen",
        slickHeadline1: "Mobile Startseite",
        slickList1Element1: "Füge den Beer Pong Turnier Planer als App zu deinem Startbildschirm hinzu",
        slickList1Element2: "Erstelle für deine Freunde ein Beer Pong Turnier",
        slickList1Element3: "Trete einem vorhandenen Turnier bei",
        slickList1Element4: "Alle deine aktiven Turniere im Überblick",
        slickHeadline2: "Turnierdetails im Überblick",
        slickList2Element1: "Datum, Startzeit, Gründer und vieles mehr auf einem Blick",
        slickList2Element2: "Gruppenmodus oder K.o.-System möglich",
        slickList2Element3: "Team- und Gruppenanzahl variabel einstellbar",
        slickList2Element4: "Berechnung der geschätzten Turnierdauer",
        slickHeadline3: "Liste der Teams",
        slickList3Element1: "Eigene Teams zum Turnier hinzufügen oder einem Team beitreten",
        slickList3Element2: "Als Gründer Teams bearbeiten oder löschen",
        slickList3Element3: "Liste nach Teamnamen filtern",
        slickHeadline4: "Übersicht der Spiele",
        slickList4Element1: "Gruppen- und Finalspiele werden automatisch für dich erstellt",
        slickList4Element2: "Als Gründer jederzeit den Spielstand ändern",
        slickList4Element3: "Tisch und Spielreihenfolge der Teams sichtbar",
        slickList4Element4: "Spiele nach Gruppen und Tische filtern",
        slickHeadline5: "Beer Pong Match",
        slickList5Element1: "Spielergebnis nach Ablauf der Zeit oder bei frühzeitigem Gewinn vom Team eintragen lassen",
        slickList5Element2: "Timer für besseres Spielerlebnis",
        slickList5Element3: "Spielzeit vom Gründer einstellbar",
        slickHeadline6: "Gruppen- und Turnierstatistik",
        slickList6Element1: "Berechnete Empfehlungen für Anzahl der Gruppen, Gruppenspiele und Teams pro Gruppe",
        slickList6Element2: "Zufällige Aufteilung der Teams in Gruppen",
        slickList6Element3: "Statistik und Platzierung in der Gruppe sichtbar",
        slickList6Element4: "Live-Aktualisierung nach Beendigung der Spiele",
        startNowHeaderPart1: "Veranstalte dein",
        startNowHeaderPart2: "Beer Pong Turnier",
        startNowHeaderPart3: "ohne viel zu planen",
        footerLinkText1: "Impressum",
        footerLinkText2: "Datenschutz",
        footerLinkText3: "Kontakt",
    },
    home: {
        headline1: "Beer Pong Turnier Planer",
        headline2Turniere: "Deine Turniere",
        headline2EmptyTurnier: "Starte deine Beer Pong Karriere!",
        textEmptyTurnier: "Veranstalte für dich und deine Freunde ein Beer Pong Turnier und mach den Abend unvergesslich. ODER nimm an einem Turnier teil und zeige allen wie ein Gewinner aussieht.",
        greeting: "Hi",
        teaserTurnierErstellen: "Turnier erstellen",
        teaserTurnierBeitreten: "Turnier beitreten"
    },
    login: {
        headline: "Login",
        imageAlt: "Beer Pong Stock Image",
        buttonText1: "Einloggen",
        buttonText2: "Account erstellen",
    },
    spielerForm: {
        labelVorname: "Vorname",
        labelNachname: "Nachname",
        labelEMail: "E-Mail",
        labelPasswort: "Passwort",
        labelPasswortNew: "Neues Passwort",
        labelPasswortOld: "Altes Passwort",
        labelPasswortRepeat: "Passwort wiederholen",
        labelDatenschutzConfirm1: "Zustimmung zur",
        labelDatenschutzConfirm2: "Datenschutzerklärung",
        labelDatenschutzConfirm3: "",
        vornameRequired: "Bitte gib einen Vornamen ein",
        vornameMinLength: "Buchstaben sind mind. notwendig",
        nachnameRequired: "Bitte gib einen Nachnamen ein",
        nachnameMinLength: "Buchstaben sind mind. notwendig",
        passwortRequired: "Bitte gib ein Passwort ein",
        passwortMinLength: "Zeichen sind mind. notwendig",
        passwortConfirm: "Die Passwörter müssen übereinstimmen",
        emailRequired: "Gib bitte eine gültige E-Mail ein",
        datenschutzConfirmRequired: "Bitte stimme den Datenschutzbestimmungen zu.",
        buttonTextAgree: "Bestätigen",
        buttonTextCancel: "Abbrechen",
        linkForgotPassword: "Passwort vergessen?"
    },
    createAccount: {
        headline: "Account erstellen",
        imageAlt: "Beer Pong Stock Image",
        buttonText1: "Registrieren",
        buttonText2: "Abbrechen",
        successHeadline: "Erfolgreich Registriert",
        successMessage: "Bitte bestätige deine E-Mail Adresse"
    },
    confirmAccount: {
        buttonText: "Zum Login",
        headline1Success: "Account verifiziert",
        messageSuccess: "Vielen Dank für die Bestätigung deiner E-Mail Adresse.",
    },
    forgotPasswortConfirm: {
        headline1: "Passwort zurücksetzen",
        successHeadline: "Passwort erfolgreich geändert",
        buttonText: "Ändern",
        buttonTextBack: "Zurück Zum Login",
    },
    forgotPasswortEmail: {
        headline1: "Passwort zurücksetzen",
        message: "Bitte gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen. Überprüfe wenn möglich auch deinen Spam-Ordner oder entblockiere den Absender:",
        emailSender: "no-reply@beerpongfriends.com",
        buttonText: "Absenden",
        buttonTextBack: "Zurück",
        successHeadline: "E-Mail versendet"
    },
    spielerDetail: {
        headline: "Spieler Details",
        successHeadline: "Daten erfolgreich geändert",
        buttonTextAbbrechen: "Abbrechen",
        buttonTextAgree: "Bestätigen",
        editPasswortHeadline: "Passwort ändern",
        editSpielerHeadline: "Spielerdaten ändern",
        passwortOldRequired: "Bitte gib dein altes Passwort ein",
        passwortNewRequired: "Bitte gib ein neues Passwort ein",
        erstellteTurniere: "erstellte Turniere",
        aktiveTurniere: "aktive Turniere",
        vergangeneTurniere: "inaktive Turniere",
        changeSpielerDaten: "Daten ändern",
        changePasswort: "Passwort ändern",
        changeLanguage: "Sprache ändern",
        changeLanguageDE: "Deutsch",
        changeLanguageEN: "Englisch",
        logout: "Logout",
    },
    turnierForm: {
        labelTurniername: "Turniername",
        labelDatum: "Datum",
        labelStartzeit: "Startzeit",
        labelVeranstaltungsort: "Veranstaltungsort (Stadt)",
        labelStadt: "Stadt",
        labelPasswort: "Passwort",
        labelBeschreibung: "öffentl. Beschreibung (optional)",
        labelTische: "Anzahl Tische",
        labelTeams: "Anzahl Teams",
        labelSpielModusEmpfehlung: "Spielmodus Empfehlung:",
        labelSpielmodus: "Spielmodus",
        labelSpieleKOTeam: "Anzahl Spiele pro Team",
        labelSpieleKOAll: "Anzahl Spiele Turnier",
        labelGruppen: "Anzahl Gruppen",
        labelEmpfehlungGruppen: "Empfehlung Anzahl an Gruppen:",
        labelSpielerProGruppe: "Anzahl Spieler pro Gruppe:",
        labelSpieleGruppenphase: "Anzahl Gruppenphasenspiele:",
        labelGruppenPhaseZweiterWeiter: "Gruppenphasenzweiter Weiter?",
        labelGruppenPhaseZweiterWeiterDetail: "2. Gruppenphasen weiter",
        hinweisTextZeit: "Trage 0 ein für keine Zeitbegrenzung.",
        labelPausenzeiten: "Pausenzeiten",
        labelSpielZeitKO: "Spielzeit",
        labelSpielZeit: "Spielzeit",
        labelZeitEinheit: "Minuten",
        labelZeitEinheitShort: "Min.",
        labelSpielZeitGruppenphase: "Spielzeit Gruppenphase",
        labelSpielZeit256: "Spielzeit 256tel Finale",
        labelSpielZeit128: "Spielzeit 128tel Finale",
        labelSpielZeit64: "Spielzeit 64tel Finale",
        labelSpielZeit32: "Spielzeit 32tel Finale",
        labelSpielZeit16: "Spielzeit 16tel Finale",
        labelSpielZeit8: "Spielzeit Achtelfinale",
        labelSpielZeit4: "Spielzeit Viertelfinale",
        labelSpielZeit2: "Spielzeit Halbfinale",
        labelSpielZeitPlatz3: "Spielzeit Platz 3",
        labelSpielZeitFinale: "Spielzeit Finale",
        labelGruender: "Gründer",
        labelGeplantePausenzeiten: "geplante Pausenzeit",
        turnierNameRequired: "Turniername notwendig",
        turnierNameMinLength: "Buchstaben erlaubt",
        passwortRequired: "Bitte gib ein Passwort ein",
        passwortMinLength: "Zeichen sind mind. notwendig",
        datumRequired: "Bitte gib ein Datum ein",
        startZeitRequired: "Bitte gib eine Startzeit ein",
        anzTischeRequired: "Bitte gib eine Tischanzahl ein",
        anzTischeMinValue: "Tisch mind. notwendig",
        anzTeamsRequired: "Bitte gib eine Teamanzahl ein",
        anzTeamsBetweenValue: "Teams möglich",
        anzGruppenRequired: "Bitte gib eine Gruppenanzahl ein",
        anzGruppenMinValue: "Gruppen mind. notwendig",
        pausenZeitenRequired: "Bitte gib eine Pausenzeit ein",
        pausenZeitenMinValue: "mind. notwendig",
        spielzeitRequired: "Bitte gib eine Spielzeit ein",
        stadtRequired: "Bitte gib eine Stadt ein",
        spielzeitMinValue: "mind. notwendig",
        spielModusKO: "K.-o.-System (1 vs 1)",
        spielModusGruppen: "Gruppen-System",
        spielModusNull: "...gib eine Teamanzahl ein",
        spielZeit: "Geschätzte Turnierdauer",
        spielZeitenHinweisHeadline: "Hinweis Berechnung Turnierdauer",
        spielZeitenHinweis: "Bei Auswahl keiner Zeitbegrenzung wird für die Berechnung eine Spielzeit von ca. 15 Minuten verwendet. In der Berechnung sind die Pausenzeiten inbegriffen.",
        spielZeitenHinweisGruppen: "Eine Gruppe spielt immer am selben Tisch.",
        spielZeitenHinweisKO: "Beim K.-o.-System sind in der Turnierdauer-Berechnung nur max. 2 Tische eingeplant.",
        turnierDatumHinweisHeadline: "Hinweis Turnierdatum",
        turnierDatumHinweis: "Ist das Turnier sieben Tage nach Turnierbeginn noch nicht beendet oder gestartet, wird dieses gelöscht.",
    },
    createTurnier: {
        headline: "Turnier erstellen",
        buttonNext: "Weiter",
        buttonSubmit: "Erstellen",
        successHeadline: "Erfolgreich Erstellt",
    },
    editTurnier: {
        headline: "Turnier bearbeiten",
        buttonNext: "Weiter",
        buttonSubmit: "Ändern",
        successHeadline: "Erfolgreich Geändert",
        notEditableMessage: "Das Turnier hat begonnen. Diese Felder sind nicht mehr editierbar."
    },
    listTurniere: {
        headline: "Turniere",
        headlinePrefixPrevious: "inkative",
        headlinePrefixCurrent: "aktive",
        emptyStateLabelActive: "Starte etwas Unvergessliches",
        emptyStateDescriptionActive: "Bisher gibt es noch kein Beer Pong Turnier in deiner Nähe. Das ist deine Chance, um deinen Freunden einen unvergesslichen Abend zu ermöglichen.",
        emptyStateLabelInactive: "Keine vergangenen Turniere vorhanden",
        emptyStateDescriptionInactive: "Bisher gab es noch keine vergangenen Beer Pong Turniere in deiner Nähe.",
        emptyStateLabelSearch: "Kein Treffer",
        emptyStateDescriptionSearch: "Zu deinem Suchbegriff gibt es leider kein Ergebnis.",
        searchInputPlacholder: "Nach einem Turnier suchen...",
        changeCityFormHeadline: "Standort",
        changeCityFormMessage: "Finde veranstaltete Turniere aus deiner Stadt.",
        stadtRequired: "Bitte gib eine Stadt ein",
        buttonTextCancel: "Abbrechen",
        buttonTextAgree: "Bestätigen",
        labelTeam: "Teams",
        filterVergangeneTurnier: "vergangene Turniere",
        filterAktuelleTurnier: "aktuelle Turniere",
        filterLabelStadt: "Stadt",
        searchInputLabel: "Turniersuche"
    },
    detailTurnier: {
        textYes: "Ja",
        textNo: "Nein",
        buttonTextBeitreten: "Teilnehmen",
        buttonTextSpiele: "Spielplan",
        buttonTextStartenKoPhase: "Spielplan erstellen",
        buttonTextStartenGruppenphase: "Gruppenphase erstellen",
        buttonTextStartenFinalPhase: "Finalphase erstellen",
        buttonTextTeams: "Teams",
        imageAlt: "Beer Pong Stock Image",
        dialogHeadlineDelete: "Das Turnier löschen?",
        dialogHeadlineCreateGruppenPhase: "Gruppenphase erstellen?",
        dialogDescriptionCreateGruppenPhase: "Die Gruppeneinteilung und Gruppenspiele werden zufällig erstellt. Eine Gruppe spielt immer am selben Tisch.",
        dialogHeadlineStartFinalPhase: "Finalphase erstellen?",
        dialogDescriptionStartFinalPhase: "Die besten Spieler der Gruppe werden in dieser Reihenfolge ausgesucht: Anzahl der Siege, Punktedifferenz, Getroffene Becher.",
        dialogHeadlineKoPhase: "Spielplan erstellen?",
        dialogDescriptionKoPhase: "Jedes Team spielt gegen jedes andere angemeldete Team, ohne Rückrunde.",
        buttonDialogCancel: "Abbrechen",
        buttonDialogAgree: "Bestätigen",
        successHeadlineTurnierPlanErstellt: "Erfolgreich Erstellt",
        successHeadlineStartFinalPhase: "Erfolgreich Erstellt",
        successHeadlineDelete: "Erfolgreich Gelöscht",
        modalTeamsMissingHeadline: "Warte noch kurz...",
        modalTeamsMissingMessage1: "es haben sich noch nicht alle Teams eingetragen. Noch ",
        modalTeamsMissingMessage2: "notwendig. Warte noch auf die letzten Teams, erstelle ein leeres Team oder passe die Teamanzahl des Turniers an.",
        modalFinalPhase: "die Gruppenphase ist noch nicht beendet.",
        dialogHeadlinePasswort: "Frag den Turniergründer nach dem Passwort",
        dialogPlaceholderPasswort: "Passwort eingeben...",
        buttonDialogCancel: "Abbrechen",
        buttonDialogAgree: "Bestätigen",
        emptyStateLabel: "Turnier nicht vorhanden",
        emptyStateDescription: "Das Turnier wurde leider nicht gefunden.",
        emptyStateButtonText: "Turnier Liste",
        modalFinalPhasePlatzierungHeadline: "Triff eine Entscheidung",
        modalFinalPhaseHinweis: "Bitte triff eine Auswahl.",
        modalFinalPhaseLinkText: "Zur Gruppenstatistik",
        modalFinalPhaseTeamChoose1: "Wähle",
        modalFinalPhaseTeamChoose2: "Teams",
        modalFinalPhaseTeamHeadline: ". Platz - Gruppe "
    },
    listTeams: {
        headline: "Teams",
        descriptionAdmin: "Die Spieler können ihr eigenes Team hinzufügen oder erstelle selbst ein leeres Team.",
        descriptionPlayer: "Drück auf das Plus, um ein Team zu erstellen oder trete einem Team bei.",
        NumberOfTeamsActive: "Anzahl eingeschriebener Teams: ",
        buttonTextJoin: "beitreten",
        buttonTextLeave: "verlassen",
        buttonDialogAgree: "Bestätigen",
        buttonDialogCancel: "Abbrechen",
        dialogHeadlineJoin: "Team Beitreten?",
        dialogHeadlineLeave: "Team Verlassen?",
        dialogHeadlineDeleteTeam: "Team Löschen?",
        dialogHeadlineTeam: "Wie heißt euer Team?",
        dialogPlaceholderTeam: "Teamname eingeben...",
        successHeadlineCreate: "Erfolgreich Erstellt",
        successHeadlineEdit: "Erfolgreich Geändert",
        successHeadlineChangeTeamAufbau: "Teamänderung Erfolgreich",
        successHeadlineLeave: "Erfolgreich Ausgetreten",
        emptyStateLabel: "Here for Beer?",
        emptyStateDescriptionAdmin: "Bisher haben sich noch keine Teams für das Turnier eingetragen. Sei der erste der beim Beer Pong Wahnsinn teilnimmt. Drücke auf das Plus, um ein Team zu erstellen.",
        emptyStateDescriptionPlayer: "Bisher haben noch keine Spieler ein Team erstellt. Du kannst aber auch jeder Zeit selbst beliebig viele Teams hinzufügen. Drücke dafür auf das Plus, um ein Team zu erstellen.",
        modalTeamsFullHeadlineAdmin: "Maximale Anzahl erreicht...",
        modalTeamsFullMessageAdmin: "du kannst leider keine neuen Teams mehr hinzufügen. Um mehr Teams zu erstellen ändere die Anzahl erlaubter Teams in den Turniereinstellungen.",
        modalTeamsFullHeadlineUser: "Leider zu spät...",
        modalTeamsFullMessageUser: "du kannst kein neues Team mehr erstellen. Die maximale Anzahl ist erreicht. Vielleicht hast du das nächste Turnier mehr Glück.",
        searchInputPlacholder: "Nach einem Team suchen...",
        searchInputLabel: "Teamsuche",
        emptyStateLabelSearch: "Kein Treffer",
        emptyStateDescriptionSearch: "Zu deinem Suchbegriff gibt es leider kein Ergebnis.",
        teamEditDialogHeadline: "Team bearbeiten",
        labelTeamName: "Teamname"
    },
    turnierplan: {
        headline: "Spielplan",
        navElementGruppen: "Gruppen",
        navElementGruppenSpiele: "Gruppenspiele",
        navElementTurnierbaum: "Finalspiele",
        navElementRanking: "Ranking",
        navElementSingleSpiele: "Spiele",
        emptyStateLabel: "Turnier startet gleich",
        emptyStateDescription: "Sobald sich alle Teams eingetragen haben und der Turniergründer das Turnier freigibt, kann der Spaß auch schon beginnen.",
        emptyStateLabelSpiele: "Sei Dabei!",
        emptyStateDescriptionSpiele: "Schreibe dich in ein aktives Turnier ein, um einen Spielplan anzuzeigen.",
        emptyStateButtonText: "Turnier Beitreten"
    },
    spieleList: {
        tischHeadline: "Tisch",
        buttonTextSpielen: "Spielen",
        buttonTextBearbeiten: "Bearbeiten",
    },
    listGruppenSpiele: {
        informationModalHeadline: "Fast geschafft...",
        informationModalMessage: "warte noch kurz bis das Turnier beginnt, um die ersten Spiele zu gewinnen.",
        labelGruppe: "Gruppe",
        labelTisch: "Tisch"
    },
    listGruppen: {
        labelGruppe: "Gruppe",
        abbreviationDescriptionSpiele: "*SP = Spiele",
        abbreviationDescriptionSiege: "*SI = Siege",
        abbreviationDescriptionNiederlage: "*NI = Niederlage",
        abbreviationDescriptiongetroffeneBecher: "*GB = getroffene Becher",
        abbreviationDescriptionverloreneBecher: "*VB = verlorene Becher",
        abbreviationDescriptionBecherdifferenz: "*BD = Becherdifferenz",
        abbreviationSpiele: "SP",
        abbreviationSiege: "SI",
        abbreviationNiederlage: "NI",
        abbreviationgetroffeneBecher: "GB",
        abbreviationverloreneBecher: "VB",
        abbreviationBecherdifferenz: "BD",
        rankingHeadline: "Ranking"
    },
    listFinalSpiele: {
        teamOpen: "Team ausstehend"
    },
    spielstandForm: {
        spielStandEditHeadline: "Spielstand ändern",
        spielStandAddHeadline: "Wie viel Becher hast du getroffen?",
        editSpielStandMessage: "Bedenke, bevor du die getroffenen Becher einträgst, dass bei einem Unentschieden solange weiter gespielt wird, bis einer trifft.",
        addSpielStandMessage: "Bedenke ein Unentschieden ist keine Option.",
        spielStandHeadline2: "Getroffene Becher:",
        addSuccessHeadline: "Spielstand eingetragen",
        editSuccessHeadline: "Spielstand eingetragen",
        erreichtePunkteTeam1Label: "Getroffene Becher Team 1",
        erreichtePunkteTeam2Label: "Getroffene Becher Team 2",
        erreichtePunkteTeam1Required: "Bitte gib einen Wert ein",
        erreichtePunkteTeam2Required: "Bitte gib einen Wert ein",
        erreichtePunkteTeam1Between: "Becher sind möglich",
        erreichtePunkteTeam2Between: "Becher sind möglich",
        erreichtePunkteTeam2NotSameValue: "Die Becheranzahl muss unterschiedlich sein",
        buttonTextAbbrechen: "Abbrechen",
        buttonTextAgree: "Bestätigen"
    },
    spielStarten: {
        headline: "Spiel starten",
        buttonTextSpielStarten: "Spiel Starten",
        buttonTextAbbrechen: "Abbrechen",
        buttonTextPause: "Pause",
        buttonTextPlayOn: "Play",
        buttonTextAgree: "Bestätigen",
        dialogTitleConfirm: "Spiel Abbrechen?",
        buttonTextSpielBeenden: "Spiel beenden",
        buttonTextSpielstandEintrage: "Spielstand eintragen",
        eEmptyStateLabel: "Lass dir Zeit...",
        emptyStateDescription: "Dein Spiel hat keine zeitliche Begrenzung.",
        buttonTextBack: "zurück"
    }
}
