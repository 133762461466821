import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'

export const SpringBackend = axios.create({
    // baseURL: `https://localhost:8443`,
    // baseURL: `http://ec2-63-35-252-225.eu-west-1.compute.amazonaws.com:8085`
    // baseURL: `http://localhost:8085`,
    baseURL: `https://beerpongfriends-api.herokuapp.com`
})

// SpringBackend.defaults.headers.common['Authorization'] = "Bearer " + localStorage.token;

SpringBackend.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status == 401 && router.currentRoute.name != "Login") {
            store.dispatch('logout')
            router.push({ name: 'Login' })
        }
        return Promise.reject(error);
})

SpringBackend.interceptors.request.use(
    config => {
        const token = localStorage.token;
        const language = localStorage.language;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (language) {
            config.headers['Accept-Language'] = language;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });